import React from 'react'
import {Box, Button, Divider, FormGroup, FormHelperText, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import styles from './admin.module.scss'

function Admin() {
  return (
    <Box className={styles['home-layout']}>
      <main>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant='h5' fontWeight={700}>Settings</Typography>
          <Button variant='contained' size='medium' className={styles['save']}>Save</Button>
        </Stack>
        <div className={styles['settings-cell']}>
          <Typography variant='body1'>Mint Duration</Typography>
          <FormGroup className={styles['interaction']}>
            <TextField
              variant='outlined'
              className='settings-input'
              size='medium'
              fullWidth
              placeholder='Enter Mint Duration'
              InputProps={{
                endAdornment: <Select variant='standard' disableUnderline size='small' value={1} className='input-reset'>
                  <MenuItem value={1}>Seconds</MenuItem>
                  <MenuItem value={2}>Minutes</MenuItem>
                  <MenuItem value={3}>Hours</MenuItem>
                </Select>
              }}
            />
            <FormHelperText>This is some text that assists users to do something useful</FormHelperText>
          </FormGroup>
        </div>
        <Divider />
      </main>
    </Box>
  )
}

export default Admin
