import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n/config"
import './index.scss'
import App from './App'
import { Provider } from 'react-redux'
import { Store } from './redux/store'
import { ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ReactGA from 'react-ga'

const environment = process.env.REACT_APP_ENV
const key: any = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
if (environment === 'production') {
  ReactGA.initialize(key)
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <Provider store={Store}>
    <I18nextProvider i18n={i18n}>
      <App />
      <ToastContainer />
    </I18nextProvider>
  </Provider>
)

reportWebVitals()
