import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import ErrorBG from '../../assets/images/404eth.png'
import { useNavigate } from 'react-router-dom'

function Error404() {
  const navigate = useNavigate()
  return (
    <Stack alignItems={'center'} justifyContent={'center'} height={'100%'} flex={1}>
      <div className='error-container'>
        <img src={ErrorBG} />
        <Typography variant='h4'>404</Typography>
        <Typography variant='h6' mt={1}>DON'T KNOW WHERE YOU ARE?</Typography>
        <Typography variant='h6'>WE REALLY HAVE NO IDEA EITHER</Typography>
        <Button sx={{ marginTop: '20px'}} variant='contained' size='large' onClick={() => navigate('/')}>Go back home</Button>
      </div>
    </Stack>
  )
}

export default Error404
