import * as AuthApi from '../../api/auth'
import { setSession } from '../../utils/common'

// actionTypes
export const VERIFY = 'VERIFY'
export const LOGOUT = 'LOGOUT'
export const SIGNUP = 'SIGNUP'
export const GET_USER = 'GET_USER'
export const SET_ERROR = 'SET_ERROR'
export const GET_MESSAGE = 'GET_MESSAGE'

export interface user {
  walletId: string,
}

interface GetUserAction {
  type: typeof GET_USER;
  payload: user
}

interface LogoutAction {
  type: typeof LOGOUT;
}

interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: any
}

export type AuthActionTypes = LogoutAction | GetUserAction | SetErrorAction;

export async function login(signature: any, wallet_address:any) {
  const res = await AuthApi.loginUser({signature, wallet_address})
  const access_token = res.data.accessToken
  setSession(access_token)
  // const response = await AuthApi.getUser()
  // const userData:user = response.data.user
  const wallet = {walletId: wallet_address}
  return {type: GET_USER, payload: wallet}
}

export async function getSignMessage(walletId: any) {
  const res = await AuthApi.getMessage(walletId)
  return {
    type: GET_MESSAGE,
    payload: res
  }
}

export async function getUser(address: any) {
  const userData = {walletId: address}
  return {type: GET_USER, payload: userData}
}

export async function updateWalletId(id: any) {
  const address = {walletId: id}
  return {type: GET_USER, payload: address}
}

export async function logout() {
  await AuthApi.logoutUser()
  setSession(null)
  return {
    type: LOGOUT,
  }
}

export async function setError(data: any) {
  return {
    type: SET_ERROR,
    payload: data
  }
}
