/* eslint-disable no-unused-vars */
import { Card, Grow, IconButton, Tooltip } from '@mui/material'
import React, { useRef, useState } from 'react'
import { Tree, TreeNode } from "react-organizational-chart"
import styles from './org.module.scss'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import PersonIcon from '@mui/icons-material/Person'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch"
import { StyledButton } from '../../components/StyledComponents/StyledComponents'

function NodeCell({data, collapsed, setCollapsed, utils}: any) {
  const {centerView, zoomOut, zoomIn} = utils
  function handleClick() {
    setCollapsed()

    setTimeout(() => {
      if (collapsed) {
        zoomOut(0.1, 400, 'easeOut')
      } else {
        zoomIn(0.1, 400, 'easeOut')
      }
    }, 10)

    setTimeout(() => {
      if (!collapsed) {
        centerView()
      }
    }, 400)
  }

  return <Grow in={true}>
    <div className={styles['container']}>
      <Card variant='outlined' className={styles['node-card']}>
        <div className={styles['field-icon']}>
          <PersonIcon />
        </div>
        <p>{data?.name}</p>
        {/* <Tooltip className={styles['tooltip-og']} title={<div className={styles['tooltip']}>
          <span>Sponsored: {data?.sponsored}</span>
          <span>Organization: {data?.organization}</span>
          <span>Sponsor: {data?.sponsor}</span>
        </div>}>
          <IconButton size='small' disableRipple className={styles['options']}>
            <MoreHorizRoundedIcon />
          </IconButton>
        </Tooltip> */}
        {/* <Tooltip placement='right' title={
          <div className={styles['tooltip']}>
            <b>Package Status:</b>
            <span>Purchase Not Completed</span>
          </div>
        }>
          <div className={styles['package-icon']} />
        </Tooltip> */}
        {
          data?.children &&
          <div className={styles['footer']}>
            <IconButton onClick={handleClick}>
              {
                collapsed
                  ? <KeyboardArrowDownIcon />
                  : <KeyboardArrowUpIcon />
              }
              {data?.children?.length}
            </IconButton>
          </div>
        }
      </Card>
    </div>
  </Grow>
}

function MapChildren({children, utils}: any) {
  const [collapsed, setCollapsed] = useState(true)

  return children?.map((child: any, index: any) => {
    if (child?.children) {
      return <TreeNode key={index} label={<NodeCell data={child} collapsed={collapsed} utils={utils} setCollapsed={() => setCollapsed(e => !e)} />}>
        {
          !collapsed ? <MapChildren key={index} children={child?.children} utils={utils} /> : null
        }
      </TreeNode>
    } else {
      return <TreeNode key={index} label={<NodeCell data={child} collapsed={collapsed} utils={utils} setCollapsed={() => setCollapsed(e => !e)} />}></TreeNode>
    }
  })
}

function OrgChart({data, parent}: any) {
  const [collapsed, setCollapsed] = useState(true)
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null)

  const Controls = ({ zoomIn, zoomOut, resetTransform }: any) => (
    <div className={styles['zoom-controls']}>
      <StyledButton variant='outlined' onClick={() => zoomIn()}>+ Zoom In</StyledButton>
      <StyledButton variant='outlined' onClick={() => zoomOut()}>- Zoom Out</StyledButton>
      {/* <StyledButton variant='outlined' onClick={() => resetTransform()}>Reset</StyledButton> */}
    </div>
  )

  return (
    <TransformWrapper
      initialScale={1}
      ref={transformComponentRef}
      centerOnInit={true}
      minScale={0.5}
      centerZoomedOut={true}
      doubleClick={{
        disabled: true
      }}
      wheel={{
        disabled: false,
        step: 0.01,
        smoothStep: 0.01
      }}
    >
      {(utils) => (
        <React.Fragment>
          <Controls {...utils} />
          <TransformComponent wrapperClass={styles['chart-wrapper']} contentClass=''>
            <Tree lineColor='#e4e4e6' lineBorderRadius='20px' nodePadding='20px' label={<NodeCell data={data} utils={utils} collapsed={collapsed} setCollapsed={() => setCollapsed(e => !e)} />}>
              {
                !collapsed
                  ? <MapChildren children={data?.children} utils={utils} />
                  : null
              }
            </Tree>
          </TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  )
}

export default OrgChart
