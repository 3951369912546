/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import {Box, Card, CardContent, CircularProgress, MenuItem, Select, Stack, Typography } from '@mui/material'
import styles from './org.module.scss'
import Graph from "react-graph-vis"
import { mapDataByWalletAddress } from '../../utils/common'
import { useDispatch, useSelector } from 'react-redux'
import { getWpData } from '../../redux/actions/HomeActions'
import { RootStateType } from '../../redux/interface'
import { sampleNodeData } from '../../utils/sampleNodeData'
import OrgChart from './OrgChart'
import WalletImage from '../../assets/images/connect_wallet_logo.svg'

function Org() {
  const dispatch = useDispatch()
  const regx = /^(.{7}).*(.{7})$/
  const wallet_address = useSelector((state: RootStateType) => state.auth.user.walletId)
  const [selectedNode, setSelectedNode] = useState('1')
  const [network, setNetwork] = useState<any>()
  const [allData, setAllData] = useState<any>({nodes: [], edges: []})
  const [wpData, setWpData] = useState<any>()
  const [dataMap, setDataMap] = useState<any>()
  const [loader, setLoader] = useState(false)

  const graph = {
    nodes: allData.nodes,
    edges: allData.edges
  }

  function onSelectChange(e: any) {
    setSelectedNode(e)
    network.focus(e, {
      scale: 1,
      animation: true
    })
    network.selectNodes([e])
  }

  const getData = async() => {
    try {
      setLoader(true)
      const result = await dispatch(await getWpData())
      setWpData(result.payload?.nfts)
      const address = wallet_address
      const data = mapDataByWalletAddress(result.payload?.nfts, address)
      setDataMap(data)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      setDataMap({name: wallet_address.replace(regx, '$1...$2')})
      console.log("error", error)
    }
  }

  useEffect(() => {
    if (wallet_address) {
      getData()
    }
  }, [wallet_address])

  // useEffect(() => {
  //   if (wallet_address) {
  //     const address = wallet_address
  //     const data = mapDataByWalletAddress(sampleNodeData, address)
  //     setDataMap(data)
  //   }
  // }, [wallet_address])

  return (
    <Box className={styles['home-layout']}>
      <Stack className={styles['title-controller']} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant='h5' fontWeight={700}>My Organisation</Typography>
        {/* <Stack direction={'row'} alignItems={'center'} gap={2} className={styles['select']}>
          <Typography>Filter:</Typography>
          <Select size='small' placeholder='Select Node' value={selectedNode} onChange={(e) => onSelectChange(e.target.value)}>
            {
              graph.nodes.map((key: any, index: any) => {
                return <MenuItem key={index} value={key.id}>{key.label}</MenuItem>
              })
            }
          </Select>
        </Stack> */}
      </Stack>
      {/* <Card variant='outlined' className={styles['node-list']}>
        <Graph
          graph={graph}
          options={options}
          // events={{ selectNode: (e: any) => console.log(e) }}
          getNetwork={(e: any) => {
            setNetwork(e)
          }}
        />
      </Card> */}
      <Card variant='outlined' className={styles['node-list']}>
        {
          loader
            ? <Stack className={styles['content']}>
              {/* <Typography variant='body1'>fetching data...</Typography> */}
              <CircularProgress />
            </Stack>
            : wallet_address === ''
              ? <Stack className={styles['content']}>
                <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} className={styles['empty-controller']}>
                  <img draggable={false} src={WalletImage} width={100} />
                  <Typography mt={2} variant='h5' fontWeight={600}>Wallet not connected</Typography>
                  <Typography variant='body1'>Please connect your wallet to access this page</Typography>
                </Stack>
              </Stack>
              : <OrgChart data={dataMap} />
        }
      </Card>
    </Box>
  )
}

export default Org
