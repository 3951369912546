import React from 'react'
import { RouterProvider } from 'react-router-dom'
import router from './routes/router'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { WagmiConfig } from 'wagmi'
import { arbitrum, mainnet } from 'wagmi/chains'
import { CssBaseline, ThemeProvider } from '@mui/material'
import theme from './utils/Theme'
import createEmotionCache from './utils/createEmotionCache'
import { CacheProvider } from '@emotion/react'

// 1. Get projectId
const projectId = 'e3b397846bd3ac3f3098cc0196cb325d'

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [mainnet, arbitrum]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains })

const clientSideEmotionCache = createEmotionCache()

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <CacheProvider value={clientSideEmotionCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="page-layout font-face-met">
            <RouterProvider router={router} />
          </div>
        </ThemeProvider>
      </CacheProvider>
    </WagmiConfig>
  )
}

export default App
