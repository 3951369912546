import * as DashboardApi from '../../api/dashboard'

// actionTypes
export const WHITELIST_ADDRESS = 'WHITELIST_ADDRESS'
export const ORG_DATA = 'ORG_DATA'
export const GET_LOGS = 'GET_LOGS'

export interface WhitelistAction {
  type: typeof WHITELIST_ADDRESS;
  payload: any
}

export type HomeActionTypes = WhitelistAction

export async function whiteList(params: any) {
  try {
    const response = await DashboardApi.whitelistAddress(params)
    const result = response.data
    return {
      type: WHITELIST_ADDRESS,
      payload: result
    }
  } catch (error) {
    console.log("error", error)
    throw error
  }
}

export async function getWpData() {
  try {
    const response: any = await DashboardApi.getWpData()
    return {
      type: ORG_DATA,
      payload: response?.data
    }
  } catch (error) {
    console.log("error", error)
    throw error
  }
}

