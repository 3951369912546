import { combineReducers } from 'redux'
import { AuthReducer } from './AuthReducer'
// import { HomeReducer } from './HomeReducer'

const RootReducer = combineReducers({
  auth: AuthReducer,
  // home: HomeReducer,
})

export default RootReducer
