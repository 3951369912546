import Org from './Org'
import Layout from '../../components/Layout/Layout'

const orgRoutes = [
  {
    path: '/company',
    element: <Layout><Org /></Layout>,
  },
]

export default orgRoutes
