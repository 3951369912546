/* eslint-disable no-unused-vars */
import * as React from 'react'
import styles from './AppHeader.module.scss'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuIcon from '@mui/icons-material/Menu'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import logo from '../../assets/images/mprofy-logo.svg'
import { Button, Typography, useMediaQuery } from '@mui/material'
import { Stack } from '@mui/system'
import walletLogo from '../../assets/images/connect_wallet_logo.svg'
import { Link, useLocation } from 'react-router-dom'
import { MenuItems } from '../../utils/MenuItems'

export default function LeftMenu({connectedAccount, connectWithMetamask} : any) {
  const [open, setOpen] = React.useState(false)
  const location = useLocation()
  const isMobile = useMediaQuery('(max-width:1200px)')
  var reg = /\b(\w{5})\w+(\w{5})\b/g

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  const connector = () => {
    setOpen(false)
    connectWithMetamask()
  }

  const list = (
    <Box sx={{ width: 310}}>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', marginTop: '50px' }}>
        <img src={logo} style={{ height: '200px', width: '200px' }} alt="Logo" />
        <Typography mt={1} fontWeight={600}>HOLD YOUR OWN</Typography>
      </div>
      <Divider />
      <List>
        {MenuItems.map((key, index) => (
          <ListItem key={key.label} disablePadding>
            <ListItemButton component={Link} to={key.link} onClick={() => {
              setOpen(false)
            }}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              {/* <ListItemText primary={text} /> */}
              <span>{key.label}</span>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      { isMobile &&
        <Stack direction={'column'} sx={{cursor: 'pointer'}} marginTop={'35vh'} marginLeft={'25px'} onClick={() => {
          setOpen(false)
          connectWithMetamask()
        }}>
          {connectedAccount === ''
            ? <div className={styles['logo-container']}> <img className={styles['logo']} src={walletLogo} style={{height: '30px', width: '30px', marginRight: '25px'}} />
              <Typography fontSize={'14px'} fontWeight={'800'} >Not Connected</Typography>
            </div>
            : <div className={styles['logo-container']}> <img className={styles['logo']} src={walletLogo} style={{height: '30px', width: '30px', marginRight: '25px'}} />
              <Typography fontSize={'14px'} fontWeight={'800'} >{connectedAccount.replace(reg, '$1--$2')}</Typography>
            </div>
          }
        </Stack>}
    </Box>
  )

  return (
    <div>
      <MenuIcon sx={{ fontSize: '30px'}} onClick={() => { setOpen(true) }}/>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
      >
        <Box className={styles['side-menu']}>
          <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} pb={'20px'}>
            <img src={logo} style={{ height: '200px', width: '200px' }} alt="Logo" />
            <Typography mt={1} fontWeight={600}>HOLD YOUR OWN</Typography>
          </Stack>
          <Divider className={styles['divider-custom']} />
          <div className={styles['content-control']}>
            <List>
              {MenuItems.map((key, index) => (
                <ListItem key={key.label} disablePadding>
                  <ListItemButton className={location.pathname === key.link ? styles['active'] : ''} component={Link} to={key.link} onClick={() => {
                    setOpen(false)
                  }}>
                    <ListItemIcon>
                      {key.icon}
                    </ListItemIcon>
                    <Typography variant='body1' fontWeight={700}>{key.label}</Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider className={styles['divider-custom-2']} />
            { isMobile &&
              <Stack direction={'column'} justifyContent={'flex-end'} className={styles['connected-stack']}>
                {connectedAccount === ''
                  ? <Button className={styles['logo-container']} onClick={connector} >
                    <img className={styles['logo']} src={walletLogo} style={{height: '30px', width: '30px', marginRight: '25px'}} />
                    <Typography fontSize={'14px'} fontWeight={'800'} >NOT CONNECTED</Typography>
                  </Button>
                  : <Button className={styles['logo-container']} onClick={connector}>
                    <img className={styles['logo']} src={walletLogo} style={{height: '30px', width: '30px', marginRight: '25px'}} />
                    <Typography fontSize={'14px'} fontWeight={'800'} >{connectedAccount.replace(reg, '$1--$2')}</Typography>
                  </Button>
                }
              </Stack>
            }
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  )
}
