import React from 'react'
import {Box, Stack, Typography } from '@mui/material'
import styles from './home.module.scss'
import mProfyLogo from '../../assets/images/mprofy-logo.svg'
import { StyledButton } from '../../components/StyledComponents/StyledComponents'
import { useNavigate } from 'react-router-dom'

function Home() {
  const navigate = useNavigate()
  return (
    <Box className={styles['home-layout']}>
      <main>
        <Stack justifyContent={'flex-end'} direction={'column'} flex={1} height={'100%'} width={'100%'}>
          <Stack direction={'column'} justifyContent={'center'} marginBottom={20} className={styles['center-aligner']}>
            <img width={300} height={300} src={mProfyLogo} />
            <Typography mt={1} fontWeight={600}>HOLD YOUR OWN</Typography>
            <StyledButton variant='contained' sx={{mt: 3}} className={styles['explore-button']} onClick={() => { navigate('/collection') }}>Explore</StyledButton>
          </Stack>
        </Stack>
      </main>
    </Box>
  )
}

export default Home
