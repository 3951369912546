class TokenService {
  getAccessToken() {
    return localStorage.getItem("accessToken")
  }

  checkAccessToken() {
    if (localStorage.getItem("accessToken") !== null) {
      return true
    } else return false
  }

  setAccessToken(accessToken: any) {
    localStorage.setItem("accessToken", accessToken)
  }

  removeAccessToken() {
    localStorage.removeItem('accessToken')
  }

  getRefreshToken() {
    return localStorage.getItem("refreshToken")
  }

  setRefreshToken(refreshToken: any) {
    localStorage.setItem("refreshToken", refreshToken)
  }

  removetRefreshToken() {
    localStorage.removeItem('refreshToken')
  }
}

const tokenService = new TokenService()

export default tokenService
