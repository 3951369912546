/* eslint-disable comma-spacing */
import React from "react"
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField, FormHelperText, CircularProgress, Snackbar } from '@mui/material'
import './Dialog.scss'
import { ethers } from 'ethers'
import { abi, deedContractAddress } from "../../utils/common"
import {useAccount} from "wagmi"

interface ConfirmDialogProps {
  open: boolean
  handleClose: () => void,
  selectedIds: any,
  fetchNft: any,
  setSelectedIds: any
}

function TransferDialog(props: ConfirmDialogProps) {
  const { open, handleClose } = props
  const accountDetails = useAccount()
  const [to, setTo] = React.useState('')
  const [loader, setLoader] = React.useState(false)
  const [error, setError] = React.useState('')
  const [notification, setNotification] = React.useState({
    message: '',
    open: false,
  })

  const validate = () => {
    if (!to) {
      setError('Receiver address is required.')
      return false
    }

    try {
      ethers.utils.getAddress(to)
    } catch (error) {
      setError('Invalid wallet address.')
      return false
    }

    return true
  }

  React.useEffect(() => {
    setTo('')
    setError('')
  }, [open])

  const transferNfts = async() => {
    if (!validate()) {
      return
    }
    const tokenIds = props?.selectedIds.map(Number)
    const connectedAddress = accountDetails?.address
    const from = connectedAddress
    if (window.ethereum && connectedAddress) {
      setLoader(true)
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const nftContract = new ethers.Contract(deedContractAddress, abi, signer)

        // Check if the caller is the current owner of all the NFTs
        const owner = await nftContract.ownerOf(tokenIds[0])
        if (owner !== from) {
          throw new Error("Only the current owner can transfer the NFTs")
        }

        // Check if the caller is approved for all the NFTs
        const isApproved = await nftContract.isApprovedForAll(from, to)
        if (!isApproved) {
          const tx = await nftContract.setApprovalForAll(to, true)
          await tx.wait()
        }

        // Transfer the NFTs to the new owner
        const tx = await nftContract.bulkTransfer(tokenIds, to)
        await tx.wait()

        props?.setSelectedIds([])
        await props.fetchNft()
        handleClose()
        setLoader(false)
        setNotification({
          message: 'NFT Transfer successfully',
          open: true
        })
      } catch (error) {
        console.log(error)
        setLoader(false)
        handleClose()
        setNotification({
          message: 'Something went wrong',
          open: true
        })
      }
    }
  }

  return (
    <div className="w-100">
      <Dialog
        open={open}
        onClose={handleClose}
        id="responsive-dialog-title"
        className="custom-bg-modal-transfer"
      >
        <DialogTitle textAlign={'center'} id="responsive-dialog-title" className='font-face-gm'>
          {'Please input Receiver address to initiate transfer'}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Enter Address"
            variant="standard"
            fullWidth={true}
            value={to}
            onChange={(e) => {
              setError('')
              setTo(e.target.value)
            }}
          />
          <FormHelperText>{error}</FormHelperText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button className='font-face-gm mb-3' variant="outlined" fullWidth={true} onClick={handleClose}>{'Cancel'}</Button>
          <Button className='font-face-gm mb-3 yes-btn' fullWidth={true} variant="contained" onClick={() => { transferNfts() }}>
            {loader ? <CircularProgress size={24} color="secondary" /> : 'Transfer'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={3000}
        open={notification.open}
        message={notification.message}
        onClose={() => {
          setNotification({
            message: '',
            open: false
          })
        }}
      />
    </div>
  )
}

export default TransferDialog
export { }
