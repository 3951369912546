import * as React from 'react'
import { Paper } from '@mui/material'

const VideoPlayer = ({ videoUrl }: any) => {
  return (
    <Paper elevation={3} style={{ padding: '20px' }}>
      <video width="100%" height="auto">
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Paper>
  )
}

export default VideoPlayer
