import thunk, { ThunkMiddleware } from 'redux-thunk'
import { createStore, applyMiddleware, compose, Middleware } from 'redux'
import RootReducer from './reducers/RootReducer'
// import { RootStateType } from './interface'

interface RootState {} // Replace this with your actual root state interface

const initialState: RootState = {}
const middlewares: Middleware[] = [thunk as ThunkMiddleware<RootState>]

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const devtools =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
    : compose

export const Store = createStore(
  RootReducer,
  initialState,
  compose(applyMiddleware(...middlewares), devtools)
)
