import AdminControls from './AdminControls'
import Layout from '../../components/Layout/Layout'

const adminRoutes = [
  {
    path: '/admin',
    name: 'Admin Control',
    element: <Layout><AdminControls /></Layout>,
  },
]

export default adminRoutes
