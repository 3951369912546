import request from '../utils/request'

interface queryParams {
  size: number,
  page: number,
}
export function whitelistAddress(params: queryParams) {
  return request({
    url: 'test/buyer/whitelist',
    method: 'post',
    params
  })
}

export function getWpData() {
  return request({
    url: 'nfts',
    method: 'get'
  })
}

