import axios from 'axios'
import tokenService from './tokenService'

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL_WP,
  timeout: 100000,
  withCredentials: true
})

let isRefreshing = false // Flag to track ongoing token refresh process
let refreshPromise: Promise<any> | null = null // Promise to hold the token refresh request

service.interceptors.request.use(config => {
  if (localStorage.getItem("accessToken")) {
    config.headers['Authorization'] = 'Bearer ' + tokenService.getAccessToken()
  }
  config.headers['Access-Control-Allow-Origin'] = "*"
  config.headers['Access-Control-Allow-Methods'] = "GET,PUT,POST,DELETE,PATCH,OPTIONS"
  config.headers['Accept-Language'] = 'en'
  config.headers['ngrok-skip-browser-warning'] = true
  return config
},
error => {
  Promise.reject(error)
}
)

service.interceptors.response.use(
  response => response,
  async(err) => {
    const originalConfig: any = err.config
    if (originalConfig.url !== "/sessions" && err.response) {
      if (originalConfig.url === '/sessions/refresh') {
        tokenService.removeAccessToken()
        location.reload()
        return false
      }
      if (err.response.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true

          try {
            const res = await service.get("/sessions/refresh", {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + tokenService.getAccessToken()
              },
            })
            const { accessToken } = res.data
            tokenService.setAccessToken(accessToken)

            // Retry the original request
            return service(originalConfig)
          } catch (_error) {
            console.log('_error: ', _error)
            tokenService.removeAccessToken()
            location.reload()
            return Promise.reject(_error)
          } finally {
            isRefreshing = false
          }
        } else {
          // There is an ongoing token refresh process, so wait for it to complete
          if (!refreshPromise) {
            refreshPromise = new Promise((resolve, reject) => {
              const interval = setInterval(() => {
                if (!isRefreshing) {
                  clearInterval(interval)
                  refreshPromise = null
                  resolve(service(originalConfig))
                }
              }, 100)
            })
          }

          return refreshPromise
        }
      }
    }
    return Promise.reject(err)
  }
)

export default service
