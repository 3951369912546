import { RouteObject, createBrowserRouter } from 'react-router-dom'
import dashboardRoutes from '../views/home/dashboardRoutes'
import Layout from '../components/Layout/Layout'
import Error404 from '../views/404/Error404'
import nftRoutes from '../views/nft/nftRoutes'
import adminRoutes from '../views/Admin/adminRoutes'
import orgRoutes from '../views/Org/orgRoutes'

const allRoutes: any = [
  ...dashboardRoutes,
  ...nftRoutes,
  ...adminRoutes,
  ...orgRoutes,
  {
    path: '*',
    element: <Layout><Error404 /></Layout>,
  },
]

export function getAllRoutes(): RouteObject[] {
  return allRoutes
}

const router = createBrowserRouter(allRoutes)

export default router
