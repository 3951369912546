import { AuthActionTypes, GET_USER, LOGOUT, user, SET_ERROR } from '../actions/AuthActions'
// Define the initial state of the application
export const initialState = {
  user: {
    walletId: '',
  },
  error: {
    message: '',
    errorKey: ''
  }
}

// Define the shape of the state
export interface AppState {
  user: user
  error: any
}

// Define the reducer function
export function AuthReducer(state = initialState, action: AuthActionTypes): AppState {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      }
    case LOGOUT: {
      return {
        ...state,
      }
    }
    case SET_ERROR: {
      return {
        ...state,
        error: action.payload,
      }
    }
    default:
      return state
  }
}
