import React from 'react'
import AppHeader from '../AppHeader/AppHeader'
// import AppFooter from '../AppFooter/AppFooter'
// import { isMobile } from 'react-device-detect'
// import { Card, Modal, Stack, Typography } from '@mui/material'

function Layout({children}: any) {
  return (
    <div className="page-layout font-face-met">
      <div className='sticky'>
        <AppHeader />
      </div>
      {children}
      {/* <AppFooter /> */}
      {/* <Modal
        open={isMobile}
        disableAutoFocus
      >
        <Card className={'mobile-modal'}>
          <Stack alignItems={'center'} justifyContent={'center'} className={'loader'} >
            <Typography>UNABLE TO LOAD ON MOBILE DEVICES RIGHT NOW.</Typography>
            <Typography mt={2}>PLEASE ACCESS VIA WEB BROWSER.</Typography>
          </Stack>
        </Card>
      </Modal> */}
    </div>
  )
}

export default Layout
