import Home from './Home'
import Layout from '../../components/Layout/Layout'

const dashboardRoutes = [
  {
    path: '/',
    name: 'MProfy',
    element: <Layout><Home /></Layout>,
  },
]

export default dashboardRoutes
